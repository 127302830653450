import React from 'react';

import Layout from '_components/layout';

import Contact from '_page-components/contact';
import Description from '_page-components/description';
import Footer from '_page-components/footer';
import Header from '_page-components/header';
import Interface from '_page-components/interface';
import Summary from '_page-components/summary';
import Target from '_page-components/target';

export default React.memo(() => {
  return (
    <Layout>
      <Header />
      <Description />
      <Summary />
      <Interface />
      <Target />
      <Contact />
      <Footer />
    </Layout>
  );
});
